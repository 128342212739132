import * as React from "react"

import "./qr-code.css"

import phoneImage from "assets/images/phone-app.png"
import qrCode from "assets/images/qr-code.svg"

const QRCodeUrl = () => {
  return (
    <div className="fr-container">
      <p className="fr-text">Direct op je mobiel?</p>
      <p className="fr-subtext">
        Scan deze QR-code met de camera van je toestel (of gebruik een QR
        scanner app) en download de Samen Fitter app direct op jouw iPhone of
        Android.
      </p>
      <div className="image-wrapper">
        <div className="qr-code-wrapper">
          <img className="qr-code-wrapper-img" src={qrCode} alt="qr code" />
        </div>
        <div className="phone-image-wrapper">
          <img
            className="phone-image-wrapper-img"
            src={phoneImage}
            alt="Samen Fitter"
          />
        </div>
      </div>
    </div>
  )
}

export default QRCodeUrl
